import React from "react";
import { useAuthContext } from "../context/AppProvider";
import { Link, navigate } from "gatsby";
import { IconLogout, IconSettings, IconUser } from '@tabler/icons-react';
import { ActionIcon, rem, Group, Button, Menu, Text } from '@mantine/core';

export function Profile() {
    const { userInfo, signout } = useAuthContext();

    if (userInfo) return <>
        <Menu shadow="md" width={200} withArrow offset={1}>
            <Menu.Target>
                <Group>
                    <Text visibleFrom="md">{userInfo?.username}</Text>
                    <ActionIcon variant="light" p={5}>
                        <IconUser />

                    </ActionIcon>
                </Group>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Item disabled leftSection={<IconSettings style={{ width: rem(14), height: rem(14) }} />}>
                    Settings
                </Menu.Item>
                <Menu.Item
                    onClick={() => {
                        navigate('/signin' as any)
                        signout && signout()
                    }}
                    color="red"
                    leftSection={<IconLogout style={{ width: rem(14), height: rem(14) }} />}
                >
                    Signout
                </Menu.Item>
            </Menu.Dropdown>
        </Menu >
    </>

    return <Group>
        <Link to="/signin">
            <Button variant="filled" radius={'xl'}>Sign in</Button>
        </Link>
    </Group>
}