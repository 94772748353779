import React from 'react';
import { Profile } from './Profile';
import Logo from './Logo';
import { ThemeSwitcher } from './ThemeSwitcher';
import { Box, Burger, Container, Flex, Group } from '@mantine/core';
import { AppMenu } from './Menu';
import { useAuthContext } from '../context/AppProvider';

export function MegaHeader({ opened, toggle }: any) {
    const { userInfo } = useAuthContext()
    return (
        <Container size={'100%'}>
            <Flex justify="space-between" align={'center'} h="100%" gap={'md'}>
                <Group>
                    {userInfo && <Burger opened={opened} onClick={toggle} hiddenFrom="sm" />}
                    {/* <Burger
                        opened={opened}
                        onClick={toggle}
                        hiddenFrom="sm"
                        size="sm"
                    /> */}
                    <Logo />
                </Group>
                <Group>
                    <AppMenu />
                    <ThemeSwitcher />
                    {/* <LangSwitcher /> */}
                    <Profile />
                </Group>
            </Flex>
        </Container>
    );
}