
export interface LocalizedProperty {
    id: any;
    entityId: string;
    languageId: string;
    localeKeyGroup: string;
    localeKey: string;
    localeValue: string;
    isHidden: boolean;
    isDeleted: boolean;
}

export class LocalizedModel {
    localizedProperties: LocalizedProperty[] = []
}