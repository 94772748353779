import 'react-grid-layout/css/styles.css';
import "nprogress/nprogress.css";
import "@mantine/core/styles.css";
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/dropzone/styles.css';

import React from 'react';
import { theme } from './src/theme';
import { AppProvider } from './src/context/AppProvider';
import { Providers } from './src/components/Providers';
import { Layout } from './src/components';

export const wrapPageElement = ({ element, props }: any) => {
    return <Providers theme={theme}>
        <AppProvider pageProps={props}>
            <Layout {...props}>{element}</Layout>
        </AppProvider>
    </Providers>;
};