import React from 'react';
import { MegaHeader } from './MegaHeader';
import { StyleProp, MantineColor, AppShell, Container, useMantineColorScheme, Stack, ScrollArea } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useAuthContext } from '../context/AppProvider';
import { Shortcuts } from '../modules/Shortcuts';

interface LayoutProps {
    children: React.ReactNode;
    bg?: StyleProp<MantineColor>
    location?: any
}

export function Layout({ children, location }: LayoutProps) {
    const [opened, { toggle }] = useDisclosure();
    const { colorScheme } = useMantineColorScheme()
    const { userInfo } = useAuthContext()

    return (
        <AppShell
            header={{
                height: 50,
                //collapsed: !pinned, 
                offset: true
            }}
            navbar={{
                width: 250,
                breakpoint: 'sm',
                collapsed: { mobile: !opened, desktop: !userInfo },
            }}
        >
            <AppShell.Header>
                <MegaHeader opened={opened} toggle={toggle} />
            </AppShell.Header>

            <AppShell.Navbar>
                <ScrollArea.Autosize>
                    {userInfo &&
                        <Stack>
                            {/* <Welcome /> */}
                            <Shortcuts toggle={toggle} location={location} />
                        </Stack>
                    }
                </ScrollArea.Autosize>
            </AppShell.Navbar>

            <AppShell.Main pb={15} w={'100%'} bg={colorScheme === 'dark' ? '' : '#f3f3f3'}>
                <Container size={'100%'} pt={15}>
                    {children}
                </Container>
            </AppShell.Main>
        </AppShell>

    );
}

